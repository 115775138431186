import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementui from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.use(elementui)

store.commit('setFullplay', !localStorage.getItem('fullplay'));
store.commit('setUserinfo', JSON.parse(localStorage.getItem('userinfo')));
store.commit('setDeptId', sessionStorage.getItem('dept_id'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
