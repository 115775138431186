import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'

const request = axios.create({
	baseURL: '/index.php?s=/',
	// baseURL: 'http://api.xxmyygs.com/',
	// baseURL: 'http://xxmy.82wx.cn/',
	timeout: 50000,
	headers: {
		'x-requested-with': 'XMLHttpRequest'
	}
})

// 数据请求拦截
request.interceptors.request.use(
	(config) => {
		// console.log('request interceptors:', config)
		const userinfo = JSON.parse(localStorage.getItem('userinfo')) || {}
		
		config.headers.Authorization = userinfo.token || 'no-token'

		return config
	},
	(error) => {
		// console.log('request interceptors:', error);
		return Promise.reject(error)
	}
)

// 返回响应数据拦截
request.interceptors.response.use(
	(res) => {
		// console.log('response success:', res);
		const data = res.data
		// 状态码为 2xx 范围时都会调用该函数，处理响应数据
		if(data.status == 0){
			// return Promise.resolve(data);
			return data;
		} else {
			switch(data.status){
				case 1001:
					router.push({ path: '/root' });
					break;
				case 1002:
					router.push({ path: '/login' });
					Message.warning('登录态已失效，请重新登录！');
					break;
				default:
					Message.error(data.msg);
			}
			return Promise.reject(data);
		}
	},
	(error) => {
		// console.log('response error:', error);
		if (error.response.code) {
			Message.error(error.response.data.error)
		}
		return Promise.reject(error)
	}
)

export default request
