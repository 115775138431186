import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pageactive: 'home',
		scrollbar: {},
		fullplay: true,
		userinfo: null,
		banner: [],
		external: [],
		department: [],
		department2: [],
		dept_id: '',
	},
	getters: {
		pageactive: state => state.pageactive,
		scrollbar: state => state.scrollbar,
		fullplay: state => state.fullplay,
		userinfo: state => state.userinfo,
		banner: state => state.banner,
		external: state => state.external,
		department: state => state.department,
		department2: state => state.department2,
		dept_id: state => state.dept_id,
	},
	mutations: {
		setPageactive(state, data) {
			state.pageactive = data
		},
		setScrollbar(state, data) {
			state.scrollbar = data
		},
		setFullplay(state, data) {
			state.fullplay = data
		},
		setUserinfo(state, data) {
			state.userinfo = data
			localStorage.setItem('userinfo', JSON.stringify(data))
		},
		setBanner(state, data) {
			state.banner = data
		},
		setExternal(state, data) {
			state.external = data
		},
		setDepartment(state, data) {
			state.department = data
		},
		setDepartment2(state, data) {
			state.department2 = data
		},
		setDeptId(state, data) {
			state.dept_id = data
			sessionStorage.setItem('dept_id', data);
		}
	},
	actions: {},
	modules: {}
})
