<template>
	<div class="page" id="app">
		<!-- 全屏视频 -->
		<!-- <video class="full_video" ref="videobox" autoplay loop muted @click="hideVideo" v-if="fullplay">
			<source :src="full_url" type="video/mp4" />
		</video> -->
		<el-backtop target=".page" :visibility-height="50"></el-backtop>
		<div class="navbar">
			<div class="container">				
				<div class="logo" @click="backHomePage">
					<el-image class="img" :src="base.logo" alt="logo">
						<img class="img" src="@/assets/logo.png" alt="logo" slot="error">
					</el-image>
				</div>
				<div class="content">
					<el-menu
						:default-active="pageactive"
						mode="horizontal"
						background-color="#339966"
						text-color="#fff"
						active-text-color="#ffd04b"
						:router="false"
						@select="handleSelect"
					>
						<el-menu-item index="home">首页</el-menu-item>
						
						<!-- 公司简介 -->
						<el-submenu index="intro" v-if="userinfo">
							<template slot="title">公司简介</template>
							<el-menu-item index="intro">组织架构</el-menu-item>
							<el-menu-item index="intro/personnel">人员信息管理</el-menu-item>
						</el-submenu>
						<el-menu-item index="intro" v-else>公司简介</el-menu-item>
						
						<el-menu-item index="news">新闻动态</el-menu-item>
						<el-submenu index="engineering">
							<template slot="title">工程项目</template>
							<el-menu-item index="engineering">工程项目信息</el-menu-item>
							<template v-if="userinfo">
								<el-menu-item index="engineering/contract">工程合同管理</el-menu-item>
								<el-menu-item index="engineering/plan">工程进度管理</el-menu-item>
								<el-menu-item index="engineering/completed">工程竣工资料管理</el-menu-item>
								<el-menu-item index="engineering/course">工程过程资料管理</el-menu-item>
								<el-menu-item index="engineering/dispatch">调度信息管理</el-menu-item>
							</template>
							<el-menu-item index="engineering/factory">厂务公开</el-menu-item>
							<!-- <el-menu-item index="engineering/standard">经营指标</el-menu-item> -->
						</el-submenu>
						<!-- <el-menu-item index="case">工程案例</el-menu-item> -->
						<el-menu-item index="engineering/standard">经营指标</el-menu-item>
						<el-menu-item index="honor">荣誉资质</el-menu-item>
						<!-- <el-menu-item index="message">在线留言</el-menu-item> -->
						<!-- <el-menu-item>
							<template slot="title"></template>
						</el-menu-item> -->
					</el-menu>
					<div class="login_box">
						<div class="userinfo" v-if="userinfo">
							<p>欢迎您，{{userinfo.name}}</p>
							<span class="btn" @click="exit">退出</span>
						</div>
						<el-button type="warning" size="mini" @click="goLogin" v-else>登录</el-button>
					</div>
				</div>
			</div>
		</div>
		<div class="page_content">
			<keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive"></router-view>
			<!-- <router-view /> -->
		</div>
		<div class="footbox">
			<div class="container">
				<div class="info">
					<div class="li">联系电话：{{base.tel}}</div>
					<div class="li">邮箱地址：{{base.email}}</div>
					<div class="li">联系地址：{{base.address}}</div>
				</div>
				<div class="share"></div>
				<div class="icp_box">
					<p>{{base.copyright}}</p>
					<a class="icp" href="https://beian.miit.gov.cn" target="_blank">工信部ICP备案编号：{{base.icp}}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	import { login, getBaseInfo } from '@/api/common'
	
	export default {
		name: 'Origin',
		data(){
			return {
				// active: 'home',
				full_url: '',
				base: {
					logo: '',
					tel: '400-000-000',
					email: '123123123@qq.com',
					address: '陕西省榆林市神木市滨河新区金澜大厦12楼',
					copyright: 'copyright @ 2006-2016陕西煤业化工建设（集团）有限公司洗选煤运营分公司 www.xxmyygs.com All Rights Reserved ',
					icp: '渝ICP备12006305号-1'
				},
			}
		},
		computed: {
			...mapGetters(['pageactive', 'fullplay', 'userinfo'])
		},
		mounted(){
			// let video = this.$refs.videobox;
			// video.play();
			// console.log('video:', video);
		},
		created(){
			// console.log('App:', this._data);
			this.full_url = window.full_url;
			
			getBaseInfo().then(res => {
				// console.log('base info:', res);
				
				let data = res.data;
				this.base = data.webset.base;
				this.$store.commit('setBanner', data.banners);
				this.$store.commit('setExternal', data.icons);
				this.$store.commit('setDepartment', data.xmb_list);
				this.$store.commit('setDepartment2', data.dept_info);
			})
		},
		methods: {
			handleSelect(key, keyPath){
				// console.log('navbar select:', key, keyPath, this.active);
				this.$router.push({ path: '/' + key});
			},
			hideVideo(){
				// this.$store.commit('setFullplay', false);
				// localStorage.setItem('fullplay', Date.now());
			},
			backHomePage(){
				this.$router.push({ path: '/' });
			},
			goLogin(){
				this.$router.push({ path: '/login' });
			},
			exit(){
				this.$store.commit('setUserinfo', null);
				this.$message({
					type: 'success',
					message: '恭喜您，退出成功！'
				})
			}
		}
	}
</script>

<style lang="scss">
	*{ margin: 0; padding: 0; box-sizing: border-box;}
	img{ width: 100%; vertical-align: top;}
	a{ display: inline-block; color: inherit; text-decoration: none;}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
		color: #2c3e50; padding-top: 60px; overflow-x: hidden;
	}
	.container{
		width: 100%; max-width: 1200px; margin: 0 auto; padding: 0 15px;
	}
	.ellipsis{ text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
	.full_video{ width: 100%; height: 100%; background-color: #000; position: fixed; top: 0; left: 0; z-index: 9999;}
	
	// 顶部导航
	.navbar{
		width: 100%; background-color: #339966; position: fixed; top: 0; left: 0; z-index: 99;
		.container{
			display: flex; justify-content: space-between;
			.logo{
				height: 60px; cursor: pointer;
				.img{ width: auto; height: 100%;}
			}
			.content{
				flex: 1 1 0; text-align: right;
				.login_box{
					display: inline-flex; height: 60px; align-items: center;
					.userinfo{
						font-size: 14px; color: #fff; text-align: center;
						.btn{ color: #FFD04B; margin-top: 5px; cursor: pointer;}
					}
				}
				.el-menu{ display: inline-block; vertical-align: top;}
				.el-menu.el-menu--horizontal{ border-bottom: none;}
				.el-submenu__icon-arrow{ color: #fff9;}
			}
		}
	}
	
	// 页面主体
	.page_content{
		width: 100%; min-height: calc(100vh - 260px);
	}
	
	// 底部信息
	.footbox{
		width: 100%; background-color: #444; padding: 40px 0 20px;
		.container{
			display: flex; justify-content: space-between; flex-wrap: wrap;
			padding: 0 15px; color: #999; font-size: 15px; font-weight: 300;
			.info{
				width: 50%; line-height: 24px; text-align: left;
			}
			.icp_box{
				width: 100%; line-height: 22px; margin-top: 24px; text-align: center;
				.icp{
					transition: color .3s;
					&:hover{ color: #ffd04b;}
				}
			}
		}
	}
	
</style>
