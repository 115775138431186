import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

/** Start **/
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location){
	return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location){
	return originalReplace.call(this, location).catch(err => err)
}
/** End **/

const routes = [
	{
		path: '/',
		alias: '/home',
		name: 'home',
		component: () => import('@/views/home'),
		meta: {
			keepAlive: true,	//此组件需要被缓存
		}
	},
	{
		path: '/intro',
		name: 'intro',
		component: () => import('@/views/intro')
	},
	{
		path: '/intro/detail',
		name: 'intro_detail',
		component: () => import('@/views/intro/detail.vue')
	},
	{
		path: '/intro/personnel',
		name: 'intro/personnel',
		component: () => import('@/views/intro/personnel.vue')
	},
	{
		path: '/intro/personnel_detail',
		name: 'intro/personnel_detail',
		component: () => import('@/views/intro/personnel_detail.vue')
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news')
	},
	{
		path: '/news/detail',
		name: 'news_detail',
		component: () => import('@/views/news/detail.vue')
	},
	{
		path: '/engineering',
		name: 'engineering',
		component: () => import('@/views/engineering'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/engineering/detail',
		name: 'engineering_detail',
		component: () => import('@/views/engineering/detail.vue')
	},
	{
		path: '/engineering/contract',
		name: 'engineering/contract',
		component: () => import('@/views/engineering/contract.vue')
	},
	{
		path: '/engineering/contract_detail',
		name: 'engineering/contract_detail',
		component: () => import('@/views/engineering/contract_detail.vue')
	},
	{
		path: '/engineering/plan',
		name: 'engineering/plan',
		component: () => import('@/views/engineering/plan.vue')
	},
	{
		path: '/engineering/plan_detail',
		name: 'engineering/plan_detail',
		component: () => import('@/views/engineering/plan_detail.vue')
	},
	{
		path: '/engineering/completed',
		name: 'engineering/completed',
		component: () => import('@/views/engineering/completed.vue')
	},
	{
		path: '/engineering/completed_detail',
		name: 'engineering/completed_detail',
		component: () => import('@/views/engineering/completed_detail.vue')
	},
	{
		path: '/engineering/course',
		name: 'engineering/course',
		component: () => import('@/views/engineering/course.vue')
	},
	{
		path: '/engineering/course_detail',
		name: 'engineering/course_detail',
		component: () => import('@/views/engineering/course_detail.vue')
	},
	{
		path: '/engineering/dispatch',
		name: 'engineering/dispatch',
		component: () => import('@/views/engineering/dispatch.vue')
	},
	{
		path: '/engineering/dispatch_detail',
		name: 'engineering/dispatch_detail',
		component: () => import('@/views/engineering/dispatch_detail.vue')
	},
	{
		path: '/engineering/factory',
		name: 'engineering/factory',
		component: () => import('@/views/engineering/factory.vue'),
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/engineering/factory_detail',
		name: 'engineering/factory_detail',
		component: () => import('@/views/engineering/factory_detail.vue')
	},
	{
		path: '/engineering/standard',
		name: 'engineering/standard',
		component: () => import('@/views/engineering/standard.vue')
	},
	{
		path: '/case',
		name: 'case',
		component: () => import('@/views/case')
	},
	{
		path: '/case/detail',
		name: 'case_detail',
		component: () => import('@/views/case/detail.vue')
	},
	{
		path: '/honor',
		name: 'honor',
		component: () => import('@/views/honor')
	},
	{
		path: '/message',
		name: 'message',
		component: () => import('@/views/message')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login.vue')
	},
	{
		path: '/root',
		name: 'NoPermission',
		component: () => import('@/views/root.vue')
	},
	{
		path: '/404',
		name: 'NotFound',
		component: () => import('@/views/404.vue')
	},
	{
		path: '*',
		redirect: '/404'
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition){
		// console.log('savedPosition:', savedPosition, { to, from});
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0};
		}
	}
})

// 路由前置守卫
router.beforeEach((to, form, next) => {
	// console.log('R beforeEach:', to, form);
	let active = to.name || '',
		idx = active.indexOf('_');
	if(idx != -1) active = active.substring(0, idx);
	store.commit('setPageactive', active);
	next();
})

export default router
