import request from '@/utils/axios'

//登录
export function login(param){
	return request({
		url: '/index/api/login',
		method: 'post',
		data: param
	})
}

//基础信息
export function getBaseInfo(){
	return request({
		url: '/index/api/base_info',
		method: 'get'
	})
}

//组织架构（一二级领导/项目部信息）
export function getOrganizationChart(){
	return request({
		url: '/index/api/organization_chart',
		method: 'get'
	})
}

//荣誉资质
export function getHonorList(param){
	return request({
		url: '/index/api/honor_cert',
		method: 'get',
		params: param
	})
}

//在线留言
export function getUserMessage(param){
	return request({
		url: '/index/api/user_message',
		method: 'post',
		params: param
	})
}
